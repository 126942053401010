@import url('./fonts/fonts.css');

html {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

body.layout-scroll {
  height: auto;
  overflow: auto;
  margin: 0;
}

#root.layout-scroll {
  height: 100vh;
  overflow: auto;
}

code {
  font-family: 'Inter';
}

a {
  color: #0052ff;
}

.intercom-launcher-frame {
  z-index: 1200 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

ul {
  padding-left: 16px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}
